import React from "react";

const APP_URL = process.env.REACT_APP_APP_URL;

const F25eLogo = () => {
    return (
      <div className="flex items-center justify-center w-full">
        <div className="h-10">
          <a href={APP_URL} className="opacity-100 hover:opacity-75 font-bold text-4xl">
            F25e
          </a>
        </div>
      </div>
    );
  };

  export default F25eLogo;