import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const { t } = useTranslation();

    return (
        <AppContext.Provider value={{ t }}>
            {children}
        </AppContext.Provider>
    );
};