import React from 'react';
import { useScreen, Screens } from './Contexts/ScreenContext';

const bgImage = require('./public/img/lawgic.webp');

const ChatScreen = () => {
    const { setCurrentScreen } = useScreen();

    return (
        <div>
            <div className="flex items-center px-2 cursor-pointer text-2xl" 
                onClick={() => {setCurrentScreen(Screens.Home);}}
            >
                <i class="fa-solid fa-left-long"></i>
            </div>
            <div className="flex flex-col items-left justify-left h-full bg-slate-900 p-4 rounded shadow-lg">
                <div className="h-[30vh] bg-cover bg-center rounded flex flex-col justify-end" style={{ backgroundImage: `url(${bgImage})` }}>
                </div>
            </div>

            <div className="flex flex-col items-left justify-left h-full">
                <h1 className="text-3xl font-bold">Meet Lawgic</h1>
                <div className="text-2xl font-bold">Chat with our AI to better understand the law changes</div>
                <div className="text-lg font-bold mt-4">Ask questions like:</div>
                <div className="text-lg mt-2">- What are the changes in the new law?</div>
                <div className="text-lg">- How does the new law affect my business?</div>
                <div className="text-lg">- What are the penalties for not following the new law?</div>
                <div className="text-lg">- How can I comply with the new law?</div>
                <div className="text-gray-500">Coming soon...</div>
            </div>
        </div>
    );
}

export default ChatScreen;