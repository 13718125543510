import React, { useState, useEffect } from 'react';
import { useUser } from '../Contexts/UserContext';
import { useScreen, Screens } from '../Contexts/ScreenContext';
import { likeArticle, bookmarkArticle, followTopic } from '../Lib/apiHelpers';

const BaseInteractiveButton = ({
  onToggle,
  icon,
  activeColor,
  inactiveColor = 'text-gray-200',
  animationClass = 'animate-ping',
  animationDuration = 300,
  itemKey,
  itemId,
  id,
  initialCount,
  children,
}) => {
  const { userData, refreshUserData } = useUser();
  const { setCurrentScreen } = useScreen();
  const [isToggled, setIsToggled] = useState(false);
  const [count, setCount] = useState(initialCount || 0);

  useEffect(() => {
    const newState = userData && userData[itemKey] && userData[itemKey].includes(itemId);
    setIsToggled(newState);
  }, [userData, itemKey, itemId]);

  const handleClick = async () => {
    if (!userData?.accessToken) {
      setCurrentScreen(Screens.Auth);
      return;
    }

    const previousState = isToggled;
    const iconElement = document.querySelector(`#${itemKey}-${itemId}`);
    
    setIsToggled(!isToggled);

    try {
      await onToggle(userData.uid, itemId, userData.accessToken);
      refreshUserData();
      setCount(isToggled ? count - 1 : count + 1);

      if (iconElement && !previousState) {
        iconElement.classList.add(animationClass);
        setTimeout(() => {
          iconElement.classList.remove(animationClass);
        }, animationDuration);
      }
    } catch (error) {
      console.error(error);
      setIsToggled(previousState);
    }
  };

  return (
    <div>
      <button 
        className={`px-1 py-1 text-xl rounded-md ${
          isToggled ? activeColor : inactiveColor
        }`}
        onClick={handleClick}
      >
        <i 
          id={`${id}`}
          className={icon}
        />
      </button>
      {React.Children.map(children, child => {
        if (React.isValidElement(child) && child.type === Counter) {
          return React.cloneElement(child, { count });
        }
        return child;
      })}
    </div>
  );
};

const Counter = ({ count }) => (
  <span className="ml-1 text-gray-400">{count}</span>
);

export const BookmarkButton = ({ article }) => (
  <BaseInteractiveButton
    itemId={article.slug}
    initialCount={article.bookmarks}
    onToggle={bookmarkArticle}
    icon="fa-solid fa-bookmark"
    activeColor="text-green-500"
    itemKey="bookmarks"
    id={`bookmark-${article.slug}`}
  >
    <Counter count={article.bookmarks} />
  </BaseInteractiveButton>
);

export const LikeButton = ({ article }) => (
  <BaseInteractiveButton
    itemId={article.slug}
    initialCount={article.likes}
    onToggle={likeArticle}
    icon="fa fa-heart"
    activeColor="text-red-500"
    itemKey="liked_law_changes"
    id={`like-${article.slug}`}
  >
    <Counter count={article.likes} />
  </BaseInteractiveButton>
);

export const FollowTopicButton = ({ topic }) => (
  <BaseInteractiveButton
    itemId={topic}
    onToggle={followTopic}
    icon="fa fa-tags"
    activeColor="text-yellow-500"
    itemKey="topics_followed"
    id={`follow-${topic}`}
  />
);