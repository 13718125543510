import React from 'react';
import 'tailwindcss/tailwind.css';

import Tooltip from '../../components/tooltip';

const getShortImportanceText = (importance) => {
    switch (importance) {
        case 1:
            return "Trivial";
        case 2:
            return "Minor";
        case 3:
            return "Moderat";
        case 4:
            return "Important";
        case 5:
            return "Urgent";
        default:
            return "Unknown Impact";
    }
}
const getFullImportanceText = (importance) => {
    switch (importance) {
        case 1:
            return `Impact Trivial
Modificări pur administrative
Afectează un grup foarte restrâns
Nu introduce obligații noi
Ex: Actualizări de formulare`;
        case 2:
            return `Impact Redus (Minor)
Modificări procedurale simple
Afectează un segment limitat
Schimbări ușor de implementat
Ex: Actualizări de proceduri existente`;
        case 3:
            return `Impact Moderat
Modificări semnificative
Afectează mai multe categorii moderate
Necesită efort moderat de implementare
Ex: Introducerea unor noi proceduri`;
        case 4:
            return `Impact Major (Important)
Modificări majore ale cadrului existent
Afectează categorii largi de populație
Introduce obligații importante
Ex: Reformarea unui sistem, legi noi`;
        case 5:
            return `Impact Critic (Urgent)
Modificări fundamentale ale sistemului
Impact la nivel național
Necesită restructurări semnificative
Ex: Reforme majore, schimbări sistemice`;
        default:
            return "Unknown Impact";
    }
}

const getImportanceClass = (importance) => {
    switch (importance) {
        case 1:
            return "bg-green-500 text-green-500";
        case 2:
            return "bg-blue-500 text-blue-500";
        case 3:
            return "bg-yellow-500 text-yellow-500";
        case 4:
            return "bg-orange-500 text-orange-500";
        case 5:
            return "bg-red-500 text-red-500";
        default:
            return "bg-gray-500 text-gray-500";
    }
}

const HomeArticleImportance = ({ article, isNotification }) => {
    return (
        <div className="mt-4 mb-4 flex justify-between items-center">
            <div className="flex items-center">
                <div className={`uppercase bg-opacity-20 px-3 py-1 text-sm rounded-md ${getImportanceClass(article.importance)}`}>
                    {getShortImportanceText(article.importance)}
                </div>
                <Tooltip text={getFullImportanceText(article.importance)} />
                {isNotification
                    ? <div className="ml-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                        New
                    </div>
                    : null
                }
            </div>
            <div className='text-gray-600'>{article.publish_date}</div>
        </div>
    );
}

export default HomeArticleImportance;