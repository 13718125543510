import React, { useEffect, useState } from 'react';
import { useUser } from './Contexts/UserContext';
import { generateInitialsSVG } from './Lib/user';
import { useScreen, Screens } from './Contexts/ScreenContext';
import { GiAchievement } from "react-icons/gi";

const bgImage = require('./public/img/abstract-bg.webp');

const ProfileScreen = () => {

    const { userData } = useUser();
    const { setCurrentScreen } = useScreen();
    const [imgSrc, setImgSrc] = useState(generateInitialsSVG('Guest'));

    useEffect(() => {
        if (userData) {
            if (userData.photoURL) {
                setImgSrc(userData.photoURL);
            } else {
                setImgSrc(generateInitialsSVG(userData.displayName));
            }
        }
    }, [userData]);
    const obfuscateEmail = (email) => {
        if (!email) return '';
        const [name, domain] = email.split('@');
        const [first, ...rest] = name.split('');
        const last = rest.pop();
        return `${first}${'*'.repeat(rest.length)}${last}@${domain}`;
    };

    return (
        <div>
            <div className="flex items-center px-2 cursor-pointer text-2xl" 
                onClick={() => {setCurrentScreen(Screens.Home);}}
            >
                <i class="fa-solid fa-left-long"></i>
            </div>
            <div className="flex flex-col items-left justify-left h-full bg-slate-900 p-4 rounded shadow-lg">
                <div className="h-[20vh] bg-cover bg-center rounded flex flex-col justify-end" style={{ backgroundImage: `url(${bgImage})` }}>
                <>
                    {userData &&
                        <img
                            src={imgSrc}
                            alt="User Initials"
                            className='rounded-full cursor-pointer w-20 h-20 translate-y-1/2'
                            onClick={() => {
                                setCurrentScreen(Screens.Profile);
                            }}
                        />
                    }
                </>
                </div>
                <div className="text-lg font-bold mt-4">
                    <div className="text-lg font-bold mt-8">User Profile</div>
                    <div className="text-lg mt-2">- Name: {userData?.displayName}</div>
                    <div className="text-lg mt-2">- Email: {obfuscateEmail(userData?.email)}</div>
                </div>
                <div className="text-lg font-bold mt-4">
                    <div className="text-lg font-bold mt-4">
                        <i class="fa-solid fa-chart-column"></i> Stats
                    </div>
                    <div className="text-lg mt-2">
                        {userData?.liked_law_changes?.length || 0} <i className="fa fa-heart"></i>
                        <button 
                            className="ml-4 px-1 py-1 h-8 bg-blue-500 text-white rounded hover:bg-blue-700"
                            onClick={() => setCurrentScreen(Screens.LikedArticles)}
                        >
                            Liked Changes
                        </button>
                    </div>
                    <div className="text-lg mt-2">
                        {userData?.bookmarks?.length || 0} <i className="fa fa-bookmark"></i>
                        <button 
                            className="ml-4 px-1 py-1 h-8 bg-blue-500 text-white rounded hover:bg-blue-700"
                            onClick={() => setCurrentScreen(Screens.Bookmarks)}
                        >
                            Bookmarked Changes
                        </button>
                    </div>
                </div>
                <div className="text-lg font-bold mt-4">
                    <div className="text-lg font-bold mt-4">
                        <i class="fa-solid fa-trophy"></i> Achievements
                    </div>
                    <div className="text-lg mt-2">
                        <div className="flex items-center mt-2">
                            <GiAchievement size={34} />
                            <span className="ml-2 text-gray-400">Compliance Guru</span>
                        </div>
                        <div className="flex items-center mt-2">
                            <GiAchievement size={34} />
                            <span className="ml-2 text-gray-400">Legal Eagle</span>
                        </div>
                        <div className="flex items-center mt-2">
                            <GiAchievement size={34} />
                            <span className="ml-2 text-gray-400">Law Aficionado</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileScreen;