import React from 'react';
import { FaSignOutAlt } from "react-icons/fa";
import { useUser } from '../Contexts/UserContext';
import { useScreen, Screens } from '../Contexts/ScreenContext';

import { signOut } from 'firebase/auth';
import { getAuthInstance } from '../../components/firebaseSetup';

const SignOut = () => {
    const { userData } = useUser();
    const { setCurrentScreen } = useScreen();

    const handleSignOut = () => {
        const auth = getAuthInstance();
        signOut(auth).then(() => {
          console.log('User signed out');
        }).catch((error) => {
          console.error('Error signing out:', error);
        });
    };

    return (
        <>
        { userData &&
            <button
                onClick={() => {
                    setCurrentScreen(Screens.Auth);
                    handleSignOut();
                }}
            >
                <div className="relative">
                    <FaSignOutAlt size={24} />
                </div>
            </button>
        }
        </>
    );
};

export default SignOut;