import { getAnalytics } from "firebase/analytics";
import React, { useState, useEffect, useCallback } from "react";

import "./ConsentBanner.css";

const ConsentBanner = ({ app, t }) => {
  const [consent, setConsent] = useState(null);

  const loadFirebaseAnalytics = useCallback(() => {
    getAnalytics(app);
    localStorage.setItem("firebase-analytics", JSON.stringify(true));
  }, [app]);

  useEffect(() => {
    const storedConsent = localStorage.getItem("analytics-consent");
    if (storedConsent) {
      setConsent(storedConsent);
      if (storedConsent === "accepted") {
        loadFirebaseAnalytics();
      }
    }
  }, [loadFirebaseAnalytics]);

  const handleAccept = () => {
    setConsent("accepted");
    localStorage.setItem("analytics-consent", "accepted");
    loadFirebaseAnalytics(); // Load Firebase Analytics on consent
  };

  const handleReject = () => {
    setConsent("rejected");
    localStorage.setItem("analytics-consent", "rejected");
  };

  if (consent) return null; // Don't show the banner if consent is already given

  return (
    <div className="consent-banner">
      <p>{t('consent')}</p>
      <button onClick={handleAccept}>{t('accept')}</button>
      &nbsp; | &nbsp;
      <button onClick={handleReject}>{t('reject')}</button>
    </div>
  );
};

export default ConsentBanner;