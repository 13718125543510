import { useEffect, useState } from 'react';
import axios from 'axios';
import HomeArticleImportance from '../HomeArticle/HomeArticleImportance';
import FeaturesBar from '../FeaturesBar/FeaturesBar';
import { useTranslation } from 'react-i18next';
import { useScreen, Screens } from '../Contexts/ScreenContext';

const API_URL = process.env.REACT_APP_API_URL;

const Article = ({articleId}) => {

    const [article, setArticle] = useState(null);
    const [articleUrl, setArticleUrl] = useState('');

    const { t } = useTranslation();

    const { setCurrentScreen } = useScreen();

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(`${API_URL}/lawchanges/${articleId}`);
                const rootUrl = window.location.origin;
                setArticle(response.data.pop());
                setArticleUrl(`${rootUrl}/app?articleId=${articleId}`);
            } catch (error) {
                console.error('Error fetching the article:', error);
            }
        };

        fetchArticle();
    }, [articleId]);

    return (
        <div>
            <div className="flex items-center px-2 cursor-pointer text-2xl" 
                onClick={() => {setCurrentScreen(Screens.Home);}}
            >
                <i class="fa-solid fa-left-long"></i>
            </div>
            {article ? (
                <div className="w-full max-w-full mt-4 border border-gray-400 rounded-xl p-4 transition-colors bg-slate-900 rounded-xl">

                    <HomeArticleImportance article={article} />

                    <h1 className="text-xl font-bold text-white">
                        {article.title}
                    </h1>
                    <div className="mt-3 text-slate-400 indent-8">
                        {article.technical_summary}
                    </div>

                    <div className="mt-3 text-slate-400">
                        <h2 className="text-xl">
                            Implementation Steps:
                        </h2>
                        <ol className="list-decimal list-inside space-y-2 mt-2">
                        {article.implementation_key_points.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                        </ol>
                    </div>
                    <FeaturesBar article={article} articleUrl={articleUrl}/>
                </div>
            ) : (
                <div>{t('app_artciles_server_issue')}</div>
            )}
        </div>
    );
};

export default Article;