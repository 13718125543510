export const generateInitialsSVG = (name) => {
    const initials = name.split(' ').map(part => part[0]).join('').toUpperCase().substring(0, 2);
    const svg = `
        <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
            <rect width="100" height="100" fill="#007bff" />
            <text x="50%" y="50%" font-size="40" fill="#ffffff" text-anchor="middle" alignment-baseline="central" font-family="Arial, sans-serif">
                ${initials}
            </text>
        </svg>
    `;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
};
