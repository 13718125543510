import React from 'react';
import { IoIosHelpCircle } from "react-icons/io";
import { useScreen, Screens } from '../Contexts/ScreenContext';


const AboutLink = () => {
    const { setCurrentScreen } = useScreen();

    return (
        <button
            onClick={() => {
                setCurrentScreen(Screens.About);
            }}
        >
            <div className="relative">
                <IoIosHelpCircle  size={30} />
            </div>
        </button>
    );
};

export default AboutLink;