import React, { useState, useRef, useEffect } from 'react';
import TextLabels from '../TextLabels';
import HomeArticleTitle from './HomeArticleTitle';
import HomeArticleImportance from './HomeArticleImportance';
import FeaturesBar from '../FeaturesBar/FeaturesBar';
import { useUser } from '../Contexts/UserContext';
import { clearArticleNotification } from '../Lib/apiHelpers';


const HomeArticle = ({ setCurrentPage, article, t }) => {
    const { userData, refreshUserData } = useUser();
    const [isNotification, setIsNotification] = useState(false);
    const rootUrl = window.location.origin;
    const articleUrl = `${rootUrl}/app?articleId=${article.slug}`;
    const articleRef = useRef(null);
    const timerRef = useRef(null);

    useEffect(() => {
        if (!userData || !article) {
            return;
        }
        setIsNotification(userData?.notifications && userData?.notifications?.articles?.includes(article.slug));
        if (!isNotification) {
            return;
        }
        console.log('Observing article:', article.slug);
        const currentArticleRef = articleRef.current;

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Start timer when article comes into view
                    timerRef.current = setTimeout(() => {
                      console.log('Article visible for required duration:', article.slug);
                      clearArticleNotification(userData.uid, article.slug, userData.accessToken);
                      refreshUserData();
                    }, 2000);
                  } else {
                    // Clear timer if article leaves viewport before delay
                    if (timerRef.current) {
                      clearTimeout(timerRef.current);
                      timerRef.current = null;
                    }
                  }
                });
            },
          {
            // Options for the observer:
            threshold: 0.8,    // 80% of article must be visible
            rootMargin: '0px'  // No margin around viewport
          }
        );
    
        if (currentArticleRef) {
          observer.observe(currentArticleRef);
        }
    
        // Cleanup observer on component unmount
        return () => {
          if (currentArticleRef) {
            observer.unobserve(currentArticleRef);
          }
        };
      }, [article.slug, article, refreshUserData, userData, isNotification]); // Dependency on article.slug for re-initialization if article changes
    
    return (
        <div ref={articleRef} className="mt-4 bg-slate-900 border border-gray-700 rounded-xl p-4 hover:bg-gray-900/50 transition-colors">

            {/* Importance */}
            <HomeArticleImportance isNotification={isNotification} article={article} />

            {/* Header */}
            <HomeArticleTitle article={article} articleUrl={articleUrl} />

            {/* Content */}
            <div className="text-slate-400 py-4">
                <a href={articleUrl} className="opacity-100">
                    {article.simple_explanation}
                </a>
            </div>

            <TextLabels article={article} />

            <div className="mt-4 flex justify-between items-center text-gray-500">
                <FeaturesBar setCurrentPage={setCurrentPage} article={article} articleUrl={articleUrl} t={t}/>
                <a href={articleUrl} className='px-2 py-1 bg-blue-600 rounded-md text-white bg-opacity-100 opacity-100 hover:opacity-80'>
                    {t('app_read_more')}
                </a>
            </div>

        </div>
    );
};

export default HomeArticle;
