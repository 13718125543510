import React, { useEffect, useState } from 'react';
import HomeArticle from './HomeArticle/HomeArticle';
import { useUser } from './Contexts/UserContext';
import UserWelcome from './UserWelcome';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL;

const NotificationsScreen = () => {
    const [articles, setArticles] = useState([]);
    const [totalNotifications, setTotalNotifications] = useState(0);
    const { userData } = useUser();

    const { t } = useTranslation();

    useEffect(() => {
        const notifications = userData?.notifications || [];
        setTotalNotifications(Object.values(notifications).reduce((total, array) => total + array.length, 0));
        const fetchArticles = async () => {
            try {
                const response = await fetch(`${API_URL}/notifications/${userData.uid}`, {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${userData.accessToken}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setArticles(data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, [userData.accessToken, userData?.notifications, userData.uid]);

    return (
        <div>
            <UserWelcome t={t} />
            <div className="items-center justify-between flex opacity-90 mt-4"> 
                <span>&nbsp;</span>
            </div>
            <div className="grid grid-cols-1 gap-4">
                {totalNotifications === 0 ? (
                    <div className='text-3xl text-center text-white items-center w-full'>{t('app_no_notifications')}</div>
                ) : articles.length === 0 ? (
                    <div>{t('app_artciles_server_issue')}</div>
                ) : (
                    articles.map((article, index) => (
                        <HomeArticle t={t} key={`${article.slug}-${index}`} article={article} />
                    ))
                )}
            </div>
        </div>
    );
};

export default NotificationsScreen;