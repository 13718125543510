const API_URL = process.env.REACT_APP_API_URL;

export async function likeArticle(userId, articleId, token) {
  return fetch(`${API_URL}/users/like-article`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ userId, articleId })
  }).then(r => r.json());
}

export async function bookmarkArticle(userId, articleId, token) {
  return fetch(`${API_URL}/users/bookmark-article`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ userId, articleId })
  }).then(r => r.json());
}

export async function followTopic(userId, topicId, token) {
  return fetch(`${API_URL}/users/follow-topic`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ userId, topicId })
  }).then(r => r.json());
}

export async function trackChange(userId, changeDetails, token) {
  return fetch(`${API_URL}/users/track-change`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ userId, changeDetails })
  }).then(r => r.json());
}

export async function clearArticleNotification(userId, articleId, token) {
  return fetch(`${API_URL}/notifications/${userId}/clear-article`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ articleId })
  }).then(r => r.json());
}

export async function followLaws(userId, lawIds, token) {
  return fetch(`${API_URL}/users/follow-laws`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ userId, lawIds })
  }).then(r => r.json());
}


export async function addComment(userId, articleId, commentText, token) {
  return fetch(`${API_URL}/users/add-comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ userId, articleId, commentText })
  }).then(r => r.json());
}

export async function getUserData(userId, token) {
  return fetch(`${API_URL}/users/user-data/${userId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((r) => r.json());
}
