import React, { useEffect } from 'react';
import { useScreen, Screens } from './Contexts/ScreenContext';
import { getAuthInstance } from '../components/firebaseSetup';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const AuthScreen = () => {

  const { t } = useTranslation();
  const { setCurrentScreen } = useScreen();

  useEffect(() => {

    const auth = getAuthInstance();

    // Check if an AuthUI instance already exists
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(auth);
    }

    const uiConfig = {
      signInSuccessUrl: '/',
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
          signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
        },
        GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          setCurrentScreen(Screens.Home);
          return false;
        },
      },
      credentialHelper: firebaseui.auth.CredentialHelper.NONE, // Disable account chooser
    };

    // Delay the initialization to ensure the element is available
    setTimeout(() => {
      ui.start('#firebaseui-auth-container', uiConfig);
    }, 100);
  }, [setCurrentScreen]);

  return <>
      <div className="w-full max-w-full mt-4 border border-gray-400 rounded-xl p-4 transition-colors bg-slate-900 rounded-xl">
        <h1 className="text-2xl font-bold text-center mt-4">{t('app_sign_in_page_title')}</h1>
        <p className="text-center mt-4">{t('app_sign_in_page_description')}</p>
        <div className='mt-4 py-4' id="firebaseui-auth-container"></div>
      </div>
    </>;
};

export default AuthScreen;