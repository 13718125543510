import React from 'react';
import {FollowTopicButton} from './FeaturesBar/InteractiveButton';

const TextLabels = ({ article }) => {
  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {article?.domains?.map((label, index) => (
        <span
          key={index}
          className="px-2 text-sm rounded-md border border-slate-600 bg-slate-700 text-slate-200"
        >
          <div className='flex justify-between items-center'>
            <div className='mr-2' >{label}</div>
            <FollowTopicButton article={article} topic={label} />
          </div>
        </span>
      ))}
    </div>
  );
};

export default TextLabels;
