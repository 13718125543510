import React, { useState } from 'react';
import { Info } from 'lucide-react';

const Tooltip = ({ text }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div 
      className="relative inline-block group"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      <Info size={20} className="ml-3 text-gray-600 cursor-pointer" />
      {isVisible && (
        <div className="absolute z-5 w-96 p-2 text-sm text-white bg-gray-800 rounded shadow-lg -left-20 -top-12 group-hover:block">
          <pre>{text}</pre>
          <div className="absolute w-2 h-2 bg-gray-800 rotate-45 -bottom-1 left-24" />
        </div>
      )}
    </div>
  );
};

export default Tooltip;