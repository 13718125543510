import React from 'react';
import { useUser } from './Contexts/UserContext';
import { useScreen, Screens } from './Contexts/ScreenContext';

const UserWelcome = () => {

  const { userData } = useUser();
  const { setCurrentScreen } = useScreen();
  

  const loggedInMessages = [
    "Welcome, [Name]! Stay informed on legal changes",
    "Hi, [Name]! Your legal updates are ready",
    "Hey, [Name]! Navigate the law with ease",
    "Welcome back, [Name]! Your laws, your way",
    "Hi, [Name]! Stay ahead with today’s legal updates"
  ];

  const loggedOutMessages = [
    "Sign in and track the latest law changes",
    "Sign in and get personalized legal updates",
    "Stay compliant — Sign in for legal updates",
    "Sign in and never miss important legal updates",
    "Sign in and stay informed on legal changes"
  ];

  const getCurrentMessage = (messages) => {
    const currentMinutes = new Date().getMinutes();
    const index = Math.floor(currentMinutes / 12) % messages.length;
    return messages[index];
  };

  const handleSignInClick = () => {
    setCurrentScreen(Screens.Auth);
  };

  const getMessage = () => {
    if (userData) {
      const message = getCurrentMessage(loggedInMessages).replace('[Name]', `<span class="text-orange-500">${userData.displayName}</span>`);
      return <span dangerouslySetInnerHTML={{ __html: message }} />;  
    } else {
      const message = getCurrentMessage(loggedOutMessages);
      const parts = message.split('Sign in');
      return (
        <>
          {parts[0]}
          <button 
            className='text-blue-500 underline'
            onClick={handleSignInClick}
          >
            Sign in
          </button>
          {parts[1]}
        </>
      );
    }
  };

  const getImage = (user) => {
    if (userData) {
      return user.photoURL;
    }
    return 'https://via.placeholder.com/150';
  };

  const avatar = getImage(userData);
  console.log('UserWelcome.js: avatar:', avatar);

  return (
    <div className='mt-4 welcome'>
      <h1 className='text-4xl text-center'>
        {getMessage()}
      </h1>
    </div>
  );
};

export default UserWelcome;