import React from 'react';

const HomeArticleTitle = ({ article, articleUrl }) => {
  return (
    <div className="w-full max-w-full py-4">
      <div className="flex items-center gap-3">
        <div className="min-w-0 flex-1">
          <a href={articleUrl} className="text-white hover:text-slate-200 opacity-100">
            <h2 className="text-ellipsis text-xl font-bold text-white">
            {article.title}
            </h2>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeArticleTitle;