import React, {useEffect, useState} from 'react';

import F25eLogo from '../../components/F25eLogo';

import UserNotifications from './Notifications';
import HomeLink from './HomeLink';
import AgentLink from './AgentLink';
import SignOut from './SignOut';
import AboutLink from './AboutLink';
import UserProfile from './UserProfile';
import Search from './Search';
import { ChevronRight, ArrowLeft } from 'lucide-react';

import { useUser } from '../Contexts/UserContext';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const { userData } = useUser();

  useEffect(() => {
      const notifications = userData?.notifications || [];
      setTotalNotifications(Object.values(notifications).reduce((total, array) => total + array.length, 0));
  }, [userData]);

  const handleOptionClick = () => {
    console.log('Option clicked');
    setIsOpen(false);
  };

  return (
    <>
      {/* Mobile Menu Button - Only visible below lg breakpoint */}
      {!isOpen && (
        <div className="nv:hidden fixed top-0 left-0 z-100 p-0 flex justify-center items-center h-screen w-8">
      {totalNotifications > 0 && (
          <span className="absolute mb-20 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
              {totalNotifications}
          </span>
      )}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-slate-900 rounded-r-xl text-gray h-20 border-r-2 border-slate-800 w-full justify-right"
          >
            <ChevronRight size={16} />
          </button>
        </div>
      )}

      {/* Navigation Bar */}
      <div
        className={`
          fixed rounded-r-xl flex bg-slate-900 z-40 h-full w-42
          ${isOpen ? 'translate-x-0' : '-translate-x-[100vw]'}
          transition-transform duration-300
          nv:translate-x-0 nv:left-0
        `}
        onClick={handleOptionClick}
      >
        <nav className="text-white relative w-full">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-between mb-4 py-4">
              <F25eLogo />
            </div>
            <div className="flex flex-col items-center justify-between space-y-8">
                <HomeLink />
                <Search />
                <AgentLink />
                <hr className="w-full border border-slate-800" />
                <UserProfile />
                <UserNotifications />
                <SignOut />
                <hr className="w-full border border-slate-800" />
                <AboutLink/>
                <button 
                  onClick={() => setIsOpen(false)}
                  className="z-50 p-2 rounded-lg bg-slate-900 text-white nv:hidden"
                >
                  <ArrowLeft size={24} />
                </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default NavBar;