import React from 'react';

const API_URL = process.env.REACT_APP_API_URL;

function HomeArticleRedirect2Monitorul({ pdfUrl }) {
  const handleRedirect = () => {

    const proxyUrl = `${API_URL}/fetch-pdf?url=${encodeURIComponent(pdfUrl)}`;
    window.open(proxyUrl, '_blank');
  };

  return (
      <button className='ml-3 px-1 py-1 text-sm rounded-md border border-slate-600 bg-slate-700 text-slate-200' onClick={handleRedirect}>
        <i className="fa fa-download"></i>
      </button>
  );
}

export default HomeArticleRedirect2Monitorul;
