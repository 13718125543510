import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import SubEarlyAccess from './SubEarlyAccess';
import MainApp from './MainApp';
import LanguageSwitcher from './components/LanguageSwitcher';
import AppProviders from './pwapp/Contexts/AppProviders';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={
              <>
                <LanguageSwitcher />
                <SubEarlyAccess />
              </>
            } />
            <Route path="/app" element={
              <AppProviders>
                <MainApp />
              </AppProviders>
            } />
          </Routes>
        </div>
      </Router>
    </I18nextProvider>
  );
};

export default App;