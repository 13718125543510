import React, { createContext, useContext, useState, useEffect } from "react";
import { getUserData } from "../Lib/apiHelpers";
import { onAuthStateChanged } from 'firebase/auth';
import { getAuthInstance } from '../../components/firebaseSetup';


const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // To handle loading state

  useEffect(() => {
    const auth = getAuthInstance(); // Use singleton to get auth instance
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setLoading(true);

        // User is signed in, get their token
        const token = await firebaseUser.getIdToken();
        const userId = firebaseUser.uid;

        // Fetch user data from the API
        const apiData = await getUserData(userId, token);

        // Merge Firebase data with API data
        setUserData({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
          accessToken: firebaseUser.accessToken,
          ...apiData, // Merge API data like likes, bookmarks, etc.
        });
      } else {
        // User is signed out
        setUserData(null);
      }
      setLoading(false); // Stop loading after processing
    });

    // Cleanup function to unsubscribe from auth state changes
    return () => unsubscribe();
  }, []);

  const refreshUserData = async () => {
    if (userData) {
      try {
        const token = await getAuthInstance().currentUser.getIdToken();
        const apiData = await getUserData(userData.uid, token);

        // Update user data without resetting the global loading state
        setUserData((prev) => ({
          ...prev,
          ...apiData,
          liked_law_changes: apiData.liked_law_changes || [], // Fully replace lists or similar fields
          bookmarks: apiData.bookmarks || [],
          notifications: apiData.notifications || [],
          topics_followed: apiData.topics_followed || [],
        }));

      } catch (error) {
        console.error("Error refreshing user data:", error);
      }
    }
  };

  return (
    <UserContext.Provider value={{ userData, refreshUserData, loading }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
