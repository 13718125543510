import React, { useState, useEffect } from 'react';
import { FaUserCircle } from "react-icons/fa";
import { useUser } from '../Contexts/UserContext';
import { useScreen, Screens } from '../Contexts/ScreenContext';
import { generateInitialsSVG } from '../Lib/user';

const UserProfile = () => {
    const { userData } = useUser();
    const { setCurrentScreen } = useScreen();
    const [imgSrc, setImgSrc] = useState(generateInitialsSVG('Guest'));

    useEffect(() => {
        if (userData) {
            if (userData.photoURL) {
                setImgSrc(userData.photoURL);
            } else {
                setImgSrc(generateInitialsSVG(userData.displayName));
            }
        }
    }, [userData]);

    return (
        <>
            {userData ? 
                <img
                    src={imgSrc}
                    alt="User Initials"
                    className='rounded-full cursor-pointer w-10 h-10'
                    onClick={() => {
                        setCurrentScreen(Screens.Profile);
                    }}
                />
             :
                <button
                    onClick={() => {
                        setCurrentScreen(Screens.Auth);
                    }}
                >
                    <div className="relative">
                        <FaUserCircle size={24} />
                    </div>
                </button>
            }
        </>
    );
};

export default UserProfile;