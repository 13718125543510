import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { BsFillBellFill } from "react-icons/bs";

import { useScreen, Screens } from '../Contexts/ScreenContext';
import { useUser } from '../Contexts/UserContext';

const UserNotifications = () => {

    const [totalNotifications, setTotalNotifications] = useState(0);
    const { setCurrentScreen } = useScreen();
    const { userData } = useUser();

    useEffect(() => {
        const notifications = userData?.notifications || [];
        setTotalNotifications(Object.values(notifications).reduce((total, array) => total + array.length, 0));
    }, [userData]);

    return (
        <>
        {userData &&
            <button
                onClick={() => {
                    setCurrentScreen(Screens.Notifications);
                }}
            >
                <div className="relative">
                    <BsFillBellFill size={24} />
                    {totalNotifications > 0 && (
                        <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                            {totalNotifications}
                        </span>
                    )}
                </div>
            </button>
        }
    </>
    );
};

export default UserNotifications;