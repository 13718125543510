import React, { useState, useEffect, useRef } from 'react';

const ShareButton = ({ 
    title = "Modificare Lege",
    text = "Verifica aceasta modificare de lege!",
    articleUrl = window.location.href 
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleShare = async () => {
        setShowDropdown(true);
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(articleUrl);
            alert('Link copied to clipboard!');
            setShowDropdown(false);
        } catch (error) {
            alert('Failed to copy link. Please copy the URL manually.');
        }
    };

    const shareToTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(articleUrl)}`, '_blank');
        setShowDropdown(false);
    };

    const shareToFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(articleUrl)}`, '_blank');
        setShowDropdown(false);
    };

    const shareByEmail = () => {
        window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(text + '\n\n' + articleUrl)}`;
        setShowDropdown(false);
    };

    const shareToWhatsApp = () => {
        window.open(`https://wa.me/?text=${encodeURIComponent(text + '\n\n' + articleUrl)}`, '_blank');
        setShowDropdown(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative inline-block px-1 py-1 text-xl rounded-md border-slate-600 text-slate-200">
            <button onClick={handleShare}>
                <i className="fa fa-share-alt"></i>
            </button>

            {showDropdown && (
                <div ref={dropdownRef} className="absolute mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 border border-gray-200">
                    <button
                        onClick={copyToClipboard}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                    >
                        <i className="fa fa-copy mr-2"></i> Copy Link
                    </button>
                    <button
                        onClick={shareToTwitter}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                    >
                        <i class="fa-brands fa-x-twitter mr-2"></i> X-Twitter
                    </button>
                    <button
                        onClick={shareToFacebook}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                    >
                        <i class="fa-brands fa-facebook mr-2"></i> Facebook
                    </button>
                    <button
                        onClick={shareByEmail}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                    >
                        <i className="fa fa-envelope mr-2"></i> Email
                    </button>
                    <button
                        onClick={shareToWhatsApp}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100 w-full text-left"
                    >
                        <i class="fa-brands fa-whatsapp mr-2"></i> WhatsApp
                    </button>
                </div>
            )}
        </div>
    );
};

export default ShareButton;