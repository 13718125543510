import React from 'react';
import ShareButton from './ShareButton';
import HomeArticleRedirect2Monitorul from './ArticleRedirect2Monitorul';
import HomeArticleMore from './ArticleMore';

import { BookmarkButton, LikeButton } from './InteractiveButton';

const FeaturesBar = ({ article, articleUrl }) => {

  return (
    <div className="mt-4 flex flex-row items-center justify-between w-54 space-x-4">
        <ShareButton 
          title={`${article.title}  [ More on www.lawtracker.ro ]`}
          text={`${article.simple_explanation} [ Read more on www.lawtracker.ro ]`} 
          articleUrl={articleUrl} 
        />
          <HomeArticleRedirect2Monitorul pdfUrl={article.file_url} />

          <BookmarkButton article={article} />
          <LikeButton article={article} />
          <HomeArticleMore article={article} articleUrl={articleUrl} />
    </div>
  );
};

export default FeaturesBar;