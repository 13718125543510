import React, { useState, useEffect, useRef } from 'react';

function HomeArticleMore({ article, articleUrl }) {
    const [showMenu, setShowMenu] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='relative inline-block' ref={menuRef}>
            <button 
                className='px-1 py-1 text-xl rounded-md text-slate-200'

                onClick={toggleMenu}
            >
                <i className="fa fa-ellipsis-h"></i>
            </button>
            {showMenu && (
                <div className='absolute mt-2 w-52 bg-white border border-gray-300 text-black rounded-md shadow-lg z-10 right-0 left-auto'>
                    <ul>
                        <li className='px-4 py-2 cursor-pointer'>
                            <i className="fa fa-exclamation-circle mr-2"></i> Report Issue
                        </li>
                        <li className='px-4 py-2 cursor-pointer'>
                            <i className="fa fa-gavel mr-2"></i> Track Law
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default HomeArticleMore;