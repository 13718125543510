import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeArticle from './HomeArticle/HomeArticle';
import Filters from './Filters';
import UserWelcome from './UserWelcome';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL;

const HomeScreen = () => {
    const [articles, setArticles] = useState([]);
    const [importance, setImportance] = useState('');
    const [topic, setTopic] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get(`${API_URL}/lawchanges`);
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    const filteredArticles = articles.filter(article => {
        return (
            (importance ? parseInt(article.importance) === parseInt(importance) : true) &&
            (topic ? article.topic === topic : true)
        );
    });

    return (
        <div>
            <UserWelcome t={t} />
            <div className="items-center justify-between flex opacity-90 mt-4"> 
                <span>&nbsp;</span>
                <Filters
                    importance={importance}
                    setImportance={setImportance}
                    topic={topic}
                    setTopic={setTopic}
                    t={t}
                />
            </div>
            <div className="grid grid-cols-1 gap-4">
                {filteredArticles.length === 0 ? (
                    <div>{t('app_artciles_server_issue')}</div>
                ) : (
                    filteredArticles.map((article, index) => (
                        <HomeArticle t={t} key={`${article.slug}-${index}`} article={article} />
                    ))
                )}
            </div>
        </div>
    );
};

export default HomeScreen;