import React, { useState, useRef, useEffect } from 'react';


const filterOptions = {
    all: { value: '', label: 'filter_all' },
    trivial: { value: '1', label: 'filter_trivial' },
    minor: { value: '2', label: 'filter_minor' },
    moderate: { value: '3', label: 'filter_moderat' },
    important: { value: '4', label: 'filter_important' },
    urgent: { value: '5', label: 'filter_urgent' }
};

const Filters = ({ importance, setImportance, t }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({});
    const dropdownRef = useRef(null);

    const handleImportanceChange = (event) => {
        let newImportance = event.target.value;
        setImportance(newImportance);
        setShowDropdown(false);
        setDropdownStyle({});
        console.log('Importance changed to:', newImportance);
    };

    const toggleDropdown = () => {
        setShowDropdown(prevShowDropdown => {
            const newShowDropdown = !prevShowDropdown;
            if (newShowDropdown) {
                adjustDropdownPosition();
            } else {
                setDropdownStyle({});
            }
            return newShowDropdown;
        });
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
            setDropdownStyle({});
        }
    };

    const adjustDropdownPosition = () => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            if (rect.right > window.innerWidth) {
                setDropdownStyle({ right: 0, left: 'auto' });
            } else {
                setDropdownStyle({});
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (showDropdown) {
            adjustDropdownPosition();
        }
    }, [showDropdown]);

    return (
        <div className="filters">
            <button onClick={toggleDropdown} className="filter-button w-12 mr-1">
                <i className="fa fa-sliders"></i>
            </button>
            {showDropdown && (
                <div className="dropdown px-4 py-4 border border-gray-700 rounded-xl w-64" ref={dropdownRef} style={dropdownStyle}>
                    <div className="text-gray-200 mb-2">{t('filter_importance')}</div>
                    {Object.entries(filterOptions).map(([key, value]) => (
                        <label key={key} className="py-1">
                            <input
                                type="radio"
                                name="importance"
                                value={value.value}
                                checked={importance === value.value}
                                onChange={handleImportanceChange}
                            />
                            <span className="ml-2">{t(value.label)}</span>
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Filters;