// firebaseSetup.js

import { initializeApp, getApps } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB9PQSnEKTqf5cHeQhuLV1unPMVt5z7XcY",
  authDomain: "lawtracker-af443.firebaseapp.com",
  projectId: "lawtracker-af443",
  storageBucket: "lawtracker-af443",
  messagingSenderId: "321964295092",
  appId: "1:321964295092:web:2c5121b313d156c54fe8e1",
  measurementId: "G-QM7TZ93XT4"
};

// Initialize Firebase app as a singleton
const firebaseApp = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

// Singleton pattern for auth instance
let authInstance;

export const getAuthInstance = () => {
  if (!authInstance) {
    authInstance = getAuth(firebaseApp);
  }
  return authInstance;
};

// Function to get the analytics instance
export const getAnalyticsInstance = () => {
    const { getAnalytics } = require("firebase/analytics");
    return getAnalytics(firebaseApp);
};

export { firebaseApp };
