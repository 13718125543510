import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Bell, CheckCircle, Users, ArrowRight } from 'lucide-react';

// Import the functions you need from the SDKs you need
import VerticalCTA from './components/VerticalCTA';
import IncentiveSection from './components/Incentive';
import ConsentBanner from './components/ConsentBanner';
import { logEvent } from './components/analytics'; // Custom analytics function for GDPR compliance
import KeyFeature from './components/KeyFeature';
import BottomCTA from './components/BottomCTA';
import { firebaseApp } from "./components/firebaseSetup";

const API_URL = process.env.REACT_APP_API_URL;

const launchDate = '2025-01-14T00:24:00Z';
const calculateTimeLeft = () => {
  const now = new Date();
  console.log(now);
  const launch = new Date(launchDate);
  console.log(launch);
  const timeLeftInSeconds = Math.floor((launch - now) / 1000);
  console.log(timeLeftInSeconds);
  return timeLeftInSeconds > 0 ? timeLeftInSeconds : 0;
};

const initialTimeLeft = calculateTimeLeft();

const SubEarlyAccess = () => {
  const [email, setEmail] = useState('');
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (24 * 60 * 60));
    const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((time % (60 * 60)) / 60);
    const seconds = time % 60;
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log custom analytics event when the button is clicked
    logEvent('early_access_signup_attempt', { email });

    try {
      const response = await fetch(`${API_URL}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });
      if (response.ok) {
        setMessage(t('subscription_successful'));
        setMessageType('success');
      } else {
        const responseData = await response.json();
        if (responseData.error === 'Email already subscribed') {
          setMessage(t('subscription_already_subscribed'));
        } else {
          setMessage(t('subscription_failed'));
        }
        setMessageType('error');
      }
    } catch (error) {
      setMessage(t('subscription_failed_with_error', { error: error.message }));
      setMessageType('error');
    }

    // TODO: fire events and catch issues when saving the email to the database fails.
    // record those emails for later debugging and follow-up

    setEmail('');
  };

  const fontOptions = {
    modern: "font-['Space_Grotesk']", // Modern, tech-forward
    clean: "font-['Outfit']",         // Clean, contemporary
    futuristic: "font-['Orbitron']"   // More futuristic/tech
  };

  // Choose your preferred font style here
  const selectedFont = fontOptions.modern;

  return (
    <div className="min-h-screen bg-gradient-to-br from-black-600 to-black-800 text-white">

      {/* Navbar */}
      <nav className="w-full px-4 py-4 flex container max-w-full mx-auto z-11">
          <a href="/" className='flex opacity-80'>
            <img src="logo-bell-law-removebg-preview.png" alt="LawTracker Logo" className="h-20" />
          </a>
          <a href="/" className="flex items-center opacity-90">
            <span className={`text-white text-2xl tracking-wide ${selectedFont}`}>
              LawTracker
            </span>
          </a>

      </nav>

      {/* Hero Section */}
      <header className="py-16">
        <div className="container max-w-6xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">{t('heroTitle')}</h1>
          <p className="text-xl mb-8">{t('heroSubtitle')}</p>
          <VerticalCTA email={email} setEmail={setEmail} handleSubmit={handleSubmit} t={t} />
          {message && (
            <div className={`max-w-full mx-auto mt-4 p-2 rounded ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
              {message}
            </div>
          )}
            <div className="flex flex-col sm:flex-row gap-4 justify-center max-w-3xl mx-auto text-2xl font-normal pt-16 text-green-500">
              {t('heroCountdown')} {formatTime(timeLeft)}
            </div>
            <div className="flex flex-col sm:flex-row justify-center max-w-3xl mx-auto text-m font-normal pt-4">
              {t('heroCountdownExplain')}
            </div>
        </div>
      </header>

      {/* Key Features Section */}

        <div className="container max-w-full mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">{t('featuresSectionTitle')}</h2>
          <div className="grid md:grid-cols-3 gap-12 mb-24 max-w-[1440px] mx-auto">
            <KeyFeature title={t('featuresDeadlineTrackTitle')} description={t('featuresDeadlineTrackDescription')} icon={<Bell size={48} className="mx-auto mb-4 text-white" />} index={1} />
            <KeyFeature title={t('featuresLegalUpdatesTitle')} description={t('featuresLegalUpdatesDescription')} icon={<CheckCircle size={48} className="mx-auto text-white" />} index={2} />
            <KeyFeature title={t('featuresLegalInsightsTitle')} description={t('featuresLegalInsightsDescription')} icon={<Users size={48} className="mx-auto mb-4 text-white" />} index={3} />
          </div>
        </div>

      {/* How It Works Section */}
      <section className="py-16">
        <div className="container max-w-full mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">{t('howItWorksTitle')}</h2>
          <div className="flex flex-col md:flex-row justify-around items-center">
            <div className="text-center mb-8 md:mb-0 md:w-1/4"> {/* Added md:w-1/4 */}
              <div className="w-16 h-16 bg-blue-600 text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">1</div>
              <h3 className="text-xl font-normal mb-2">{t('howItWorksStep1')}</h3>
            </div>
            <ArrowRight className="hidden md:block text-blue-600" size={32} />
            <div className="text-center mb-8 md:mb-0 md:w-1/4"> {/* Added md:w-1/4 */}
              <div className="w-16 h-16 bg-blue-600 text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">2</div>
              <h3 className="text-xl font-normal mb-2">{t('howItWorksStep2')}</h3>
            </div>
            <ArrowRight className="hidden md:block text-blue-600" size={32} />
            <div className="text-center md:w-1/4"> {/* Added md:w-1/4 */}
              <div className="w-16 h-16 bg-blue-600 text-white rounded-full flex items-center justify-center text-2xl font-bold mx-auto mb-4">3</div>
              <h3 className="text-xl font-normal mb-2">{t('howItWorksStep3')}</h3>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      {/*
      <section className="py-16">
        <div className="container max-w-full mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center">{t('testimonialsTitle')}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Alert title="John Doe, Attorney at Law">
              "LawTracker has revolutionized how I manage my cases. It's a game-changer!"
            </Alert>
            <Alert title="Jane Smith, Legal Consultant">
              "The automated reminders have saved me countless hours. Highly recommended!"
            </Alert>
          </div>
        </div>
      </section>
      */}

      {/* Incentive Section */}
      <IncentiveSection t={t} />

      {/* Final CTA Section */}
      <section className="py-16">
        <div className="container max-w-full mx-auto text-center">
          <BottomCTA email={email} setEmail={setEmail} handleSubmit={handleSubmit} t={t} />
          {message && (
            <div className={`max-w-full mx-auto mt-4 p-2 rounded ${messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
              {message}
            </div>
          )}
          {/*
          <div className="flex justify-center space-x-4">
            <a href="#" className="text-blue-600 hover:text-blue-800">Facebook</a>
            <a href="#" className="text-blue-600 hover:text-blue-800">LinkedIn</a>
            <a href="#" className="text-blue-600 hover:text-blue-800">Twitter</a>
          </div>
          */}
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container max-w-full mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <a href="https://www.newport.ro" className="text-white hover:text-gray-300">by Newport.ro</a>
              <p>Cluj, România</p>
            </div>
            <div className="mb-4 md:mb-0">
              <p>Email: info@lawtracker.com</p>
              <p>Phone: (123) 456-7890</p>
            </div>
            <div>
              <button className="text-white hover:text-gray-300">Privacy Policy</button>
            </div>
          </div>
        </div>
      </footer>

      <ConsentBanner app={firebaseApp} t={t} />
    </div>
  );
};

export default SubEarlyAccess;