import React from 'react';
import { FaSearch } from "react-icons/fa";

const Search = () => {
    return (
        <button className={`hover:text-gray-300`}>
            <div className="relative">
                <FaSearch size={24} />
            </div>
        </button>
    );
};

export default Search;