import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from "./pwapp/Contexts/UserContext";
import { useScreen, Screens } from "./pwapp/Contexts/ScreenContext";

import NavBar from './pwapp/NavBar/NavBar';
import Article from './pwapp/ArticleDetails/Article';

import InstallAppButton from './pwapp/InstallAppButton';
import AuthScreen from './pwapp/AuthScreen';
import ChatScreen from './pwapp/ChatScreen';
import AboutScreen from './pwapp/AboutScreen';
import HomeScreen from './pwapp/HomeScreen';
import ProfileScreen from './pwapp/ProfileScreen';
import NotificationsScreen from './pwapp/NotificationsScreen';
import BuildInfo from './pwapp/BuildInfo';


// App component
const App = () => {
    const { currentScreen, setCurrentScreen } = useScreen();
    const { userData } = useUser();
    const { t } = useTranslation();

    const getQueryDetails = () => {
        const params = new URLSearchParams(window.location.search);
        const articleId = params.get('articleId');
        return { articleId };
    };

    const { articleId } = getQueryDetails();

    if (articleId) {
        setCurrentScreen(Screens.Article);
    }

    return (
        <div className="app flex flex-col font-['Space_Grotesk'] z-[100]">
            <div className="flex">

                {/* Sidebar */}
                <NavBar />

                {/* Main Content */}
                <div className="flex-1 p-6">
                    <div className="mt-4">
                        {currentScreen === Screens.Home && <HomeScreen />}
                        {currentScreen === Screens.Notifications && <NotificationsScreen />}
                        {currentScreen === Screens.Auth && <AuthScreen />}
                        {currentScreen === Screens.Article && <Article articleId={articleId} userData={userData} t={t} />}
                        {currentScreen === Screens.Chat && <ChatScreen />}
                        {currentScreen === Screens.About && <AboutScreen />}
                        {currentScreen === Screens.Profile && <ProfileScreen />}
                    </div>
                </div>
            </div>
            {/* Footer */}
            <div className="flex justify-between gap-4 p-4 bg-gray-900 text-white">
                <a href="https://www.newport.ro">by Newport.ro</a> 
                <span className="text-gray-700" >
                    <BuildInfo />
                </span>
            </div>
            <InstallAppButton />
        </div>
    );
};

export default App;
