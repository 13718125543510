import React from 'react';

import { useScreen, Screens } from '../Contexts/ScreenContext';

import { GoHomeFill } from "react-icons/go";

const HomeLink = () => {

    const { setCurrentScreen } = useScreen();

    return (
            <button
                onClick={() => {
                    setCurrentScreen(Screens.Home);
                }}
            >
                <div className="relative">
                    <GoHomeFill size={24} />
                </div>
            </button>
    );
};

export default HomeLink;