import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ScreenContext = createContext();

export const Screens = {
  Home: 'Home',
  Auth: 'Auth',
  Chat: 'Chat',
  About: 'About',
  Article: 'Article',
  Profile: 'Profile',
}

// Create a provider component
export function ScreenProvider({ children }) {
  const [currentScreen, setCurrentScreenState] = useState('Home');
  const navigate = useNavigate();
  // Custom function to set the screen and navigate
  const setCurrentScreen = (screen) => {
    setCurrentScreenState(screen);
    if (screen !== Screens.Article) navigate("/app");
  };

  return (
    <ScreenContext.Provider value={{ currentScreen, setCurrentScreen }}>
      {children}
    </ScreenContext.Provider>
  );
}

// Custom hook for easier access to the context
export function useScreen() {
  return useContext(ScreenContext);
}
