import React from 'react';
import { RiChatAiFill } from "react-icons/ri";
import { useScreen, Screens } from '../Contexts/ScreenContext';

const AgentLink = () => {

    const { setCurrentScreen } = useScreen();

    return (
        <button
            onClick={() => {
                setCurrentScreen(Screens.Chat);
            }}
        >
            <div className="relative bg-gradient-to-r from-blue-500 to-pink-500 rounded-full p-1">
                <RiChatAiFill size={24} />
            </div>
        </button>
    );
};

export default AgentLink;