import React from 'react';

const AboutScreen = ({ t }) => {

    return (
        <div className="flex flex-col items-left justify-left h-full">
            <div className="text-2xl font-bold">About LawTracker</div>
            <div className="text-lg font-bold mt-4">What is LawTracker?</div>
            <div className="text-lg mt-2">- LawTracker is a web application that helps you keep track of the changes in the law that affect your business.</div>
            <div className="text-lg">- LawTracker uses AI to help you understand the law changes and how they affect your business.</div>
            <div className="text-lg">- LawTracker helps you comply with the law by providing you with the information you need to stay compliant.</div>
            <div className="text-lg">- LawTracker is free to use and available to everyone.</div>
            <div className="text-lg font-bold mt-4">Who is behind LawTracker?</div>
            <div className="text-lg mt-2">- LawTracker is developed by F25e, a company that specializes in legal technology.</div>
            <div className="text-lg">- F25e is dedicated to helping businesses stay compliant with the law by providing them with the tools they need to understand and comply with the law.</div>
            <div className="text-lg">- F25e is committed to making the law more accessible and understandable to everyone.</div>
            <div className="text-lg font-bold mt-4">How can I contact LawTracker?</div>
            <div className="text-lg mt-2">- You can contact LawTracker by sending an email to contact@lawtracker.ro.</div>

            <div className="text-lg font-bold mt-4">About F25e</div>
            <div className="text-lg mt-2">- F25e is a company that specializes in legal technology.</div>
            <div className="text-lg">- F25e is dedicated to helping businesses stay compliant with the law by providing them with the tools they need to understand and comply with the law.</div>
            <div className="text-lg">- F25e is committed to making the law more accessible and understandable to everyone.</div>
            <div className="text-lg font-bold mt-4">How can I contact F25e?</div>
            <div className="text-lg mt-2">- You can contact F25e by sending an email to
                <a href="mailto:contact@lawtracker.ro" className="text-blue-500">contact@lawtracker.ro</a>.
            </div>
            <div className="text-lg font-bold mt-4">How can I use LawTracker?</div>
            <div className="text-lg mt-2">- You can use LawTracker by signing up for an account and logging in.</div>
            <div className="text-lg mt-2">- You can use LawTracker to track the changes in the law that affect your business.</div>
            <div className="text-lg mt-2">- You can use LawTracker to understand the law changes and how they affect your business.</div>
            <div className="text-lg mt-2">- You can use LawTracker to comply with the law by providing you with the information you need to stay compliant.</div>
            <div className="text-lg mt-2">- You can use LawTracker to stay informed about the changes in the law that affect your business.</div>
            <div className="text-lg mt-2">- You can use LawTracker to navigate the law with ease.</div>
            
            

        </div>
    );
}

export default AboutScreen;