import React from 'react';
import { AppProvider } from './AppContext';
import { UserProvider } from './UserContext';
import { ScreenProvider } from './ScreenContext';

const AppProviders = ({ children }) => {
    return (
        <AppProvider>
            <UserProvider>
                <ScreenProvider>
                    {children}
                </ScreenProvider>
            </UserProvider>
        </AppProvider>
    );
}

export default AppProviders;